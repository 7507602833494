import React, { useState, useEffect } from "react"
import moment from "moment"
import styled from "@emotion/styled"

import { Button, ButtonLink } from "@components/@aaiff"

import { TicketingFeaturesList } from "./ticketing_features_list"
import { TicketingModal } from "./ticketing_modal"
import { useEleventService } from "../../hooks"

const CardContainer = styled("li")`
  display: flex;
  flex-direction: column;
  max-width: 26rem;
  width: 100%;

  @media (min-width: 800px) {
    max-width: ${props =>
      props.packType === "festivalPasses" ? "unset" : "34rem"};
  }

  @media (min-width: 1150px) {
    max-width: ${props =>
      props.packType === "festivalPasses" ? "unset" : "16rem"};
  }

  @media (min-width: 1280px) {
    max-width: ${props =>
      props.packType === "festivalPasses" ? "unset" : "24rem"};
      width: ${props => (props.packType === "festivalPasses" ? "" : "17.5rem")};
`
const CardPass = styled("div")`
  list-style: none;
  border-radius: 10px;
  border: 1px solid #f29d2e;
  overflow: hidden;

  @media (min-width: 800px) {
    height: ${props => (props.packType === "individualTickets" ? "21rem" : "")};
  }
`

const CardHeader = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  @media (min-width: 800px) {
    display: grid;
    grid-template-columns: minmax(auto, 6rem) 1fr;
    column-gap: 0.5rem;

    & > div:only-child {
      grid-column: 1/-1;
    }
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
const CardName = styled("h2")`
  font-weight: 800;
  font-size: 1.5rem;
  margin: 0.5rem 0;
  width: ${props => (props.packType === "ticketsAndPackages" ? "50%" : "")};

  @media (min-width: 800px) {
    font-size: 1.25rem;
    width: ${props => (props.packType === "ticketsAndPackages" ? "40%" : "")};
  }

  @media (min-width: 1280px) {
    font-size: 1.5rem;
  }
`
const CardContent = styled("div")`
  padding: 1.5rem;
  box-sizing: border-box;
  background-color: transparent;
  display: grid;
  height: 100%;
  grid-template-rows: ${props =>
    props.packType === "festivalPasses"
      ? "auto auto 4rem 4rem auto"
      : props.packType === "individualTickets"
      ? "2rem 6rem auto auto"
      : "4rem 3.25rem 3.5rem auto"};

  @media (min-width: 800px) {
    grid-template-rows: ${props => {
      if (props.packType === "festivalPasses") {
        return props.discounted
          ? "6rem 4rem 4rem 4rem auto"
          : "3rem 4rem 4rem 4rem auto"
      } else if (props.packType === "individualTickets") {
        ;("2rem 7rem 5.25rem auto")
      } else {
        ;("4rem 4.25rem 3.5rem auto")
      }
    }};
  }

  @media (min-width: 990px) {
    grid-template-rows: ${props =>
      props.packType === "festivalPasses"
        ? "3rem 3.5rem 4rem 4rem auto"
        : props.packType === "individualTickets"
        ? "2rem 7.5rem 5.25rem auto"
        : "4rem 4.25rem 3.5rem auto"};
  }

  @media (min-width: 1280px) {
    padding: 1.5rem;
    grid-template-rows: ${props =>
      props.packType === "festivalPasses"
        ? "4rem auto auto 4rem auto"
        : props.packType === "individualTickets"
        ? "2rem 7.5rem 5.25rem auto"
        : "4rem 4.25rem 3.5rem auto"};
  }
`
const CardDiscountTag = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;

  ${
    "" /* limiting width to prevent excess empty space on the tag when text wrap
set to 1200px for now because above this text doesn't wrap
 */
  }
  @media(min-width:800px) {
    text-wrap: wrap;
    align-items: end;
  }

  @media (min-width: 1280px) {
    flex-direction: column;
    max-width: unset;
  }
`

const CardSpecialTagContent = styled("p")`
  font-weight: 800;
  font-size: 1rem;
  background-color: #ffd99d;
  color: #d77e19;
  border-radius: 3px;
  padding: 0.3rem 1rem;
  width: fit-content;

  @media (min-width: 800px) {
    font-size: 0.9rem;
    padding: 0.3rem 0.9rem;
  }
`

const CardDiscountEndDate = styled("p")`
  font-size: 0.75rem;
`

const CardDescription = styled("p")`
  font-weight: ${props =>
    props.packType === "festivalPasses" ? "800" : "400"};
  font-size: 1rem;
  margin: 0.5rem 0;
`
const CardPriceContainer = styled("div")`
  margin: 0.25rem 0 1rem 0;
  display: flex;
  align-items: center;

  @media (min-width: 1280px) {
    margin: 0 0 1rem 0;
  }
`
const CardPrice = styled("strong")`
  font-weight: 800;
  font-size: ${props =>
    props.packType === "festivalPasses" ? "1.5rem" : "1.25rem"};
`
const CardPriceLabel = styled("p")`
  margin-left: 1rem;
  font-size: 1.05rem;

  @media (min-width: 800px) {
    font-size: 1.1rem;
  }

  @media (min-width: 1280px) {
    font-size: 1.25rem;
  }
`
const CardCrossOffPrice = styled("span")`
  font-weight: 800;
  font-size: 1.5rem;
  color: #cccccc;
  margin-right: 0.5rem;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    left: 0;
    top: 45%;
    right: 0;
    border-top: 3px solid;
    border-color: inherit;
    transform: skewY(-10deg);
  }
`

const CardButtonBox = styled("div")`
  button {
    width: 100%;
  }
`

const CardLabel = styled("p")`
  font-weight: bold;
  margin: 1rem 0;
`

const CardMiddle = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: start;
`
const CardPriceText = styled("p")`
  margin: 0;
`

export function TicketingCard(props) {
  const [openModal, setOpenModal] = useState(false)
  const eleventService = useEleventService()

  const handleOpenModal = () => {
    //when Modal opens, initialize the elevent widget.
    setOpenModal(true)
    eleventService.initializeWidgets()
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const {
    packType,
    ticketingItem,
    buttonLabel = "Add To Cart",
    showFeatureIcons = true,
    hidePrice = false,
    linkTo,
    cardLabel,
  } = props

  const saleFinalDate = moment(
    ticketingItem.discountFinalDate,
    "MM/DD/YYYY"
  ).format("MMMM DD, YYYY")

  const elevent = ticketingItem.getEleventInformation()

  return (
    <CardContainer packType={packType}>
      {cardLabel ? <CardLabel>{cardLabel}</CardLabel> : null}
      <CardPass packType={packType}>
        <CardContent
          packType={packType}
          discounted={ticketingItem.isDiscounted()}
        >
          <CardHeader>
            <div>
              <CardName packType={packType}>{ticketingItem.name}</CardName>
            </div>
            {ticketingItem.isDiscounted() ? (
              <CardDiscountTag>
                <CardSpecialTagContent>
                  {ticketingItem.discountName}
                </CardSpecialTagContent>
                <CardDiscountEndDate>{`Sale ends on ${saleFinalDate}`}</CardDiscountEndDate>
              </CardDiscountTag>
            ) : null}
          </CardHeader>

          <CardDescription packType={packType}>
            {ticketingItem.description}
          </CardDescription>
          <CardMiddle>
            {packType === "individualTickets" ? (
              <CardPriceText>Starting at</CardPriceText>
            ) : null}
            {
              <CardPriceContainer>
                {ticketingItem.isDiscounted() ? (
                  <CardCrossOffPrice>
                    ${ticketingItem.getOriginalPrice(true)}
                  </CardCrossOffPrice>
                ) : null}
                <CardPrice packType={packType}>
                  ${ticketingItem.getPrice(true)}
                </CardPrice>
                {packType === "festivalPasses" ? null : (
                  <CardPriceLabel>{ticketingItem.priceLabel}</CardPriceLabel>
                )}
              </CardPriceContainer>
            }
          </CardMiddle>

          {packType === "individualTickets" ? (
            <CardButtonBox>
              <ButtonLink
                variant={ticketingItem.isHighlighted ? "black" : "white"}
                isDisabled={!ticketingItem.isAvailable}
                to={linkTo}
              >
                {ticketingItem.isAvailable
                  ? buttonLabel
                  : ticketingItem.unavailableButtonLabel}
              </ButtonLink>
            </CardButtonBox>
          ) : (
            <CardButtonBox>
              <Button
                variant={ticketingItem.isHighlighted ? "black" : "white"}
                isDisabled={!ticketingItem.isAvailable}
                onClick={handleOpenModal}
              >
                {ticketingItem.isAvailable
                  ? buttonLabel
                  : ticketingItem.unavailableButtonLabel}
              </Button>
            </CardButtonBox>
          )}

          {openModal && (
            <TicketingModal
              isOpen={openModal}
              closeModal={handleCloseModal}
              ticketName={ticketingItem.name}
              content={ticketingItem.purchaseInstructions}
              elevent={elevent}
              linkTo={linkTo}
              isAvailable={ticketingItem.isAvailable}
            />
          )}

          {packType === "festivalPasses" ? (
            <TicketingFeaturesList
              ticketingItem={ticketingItem}
              showFeatureIcons={showFeatureIcons}
            />
          ) : null}
        </CardContent>
      </CardPass>
    </CardContainer>
  )
}
